import React from "react";

import LostLogo from "../../assets/images/lostboysocial.png";
import Discord from "../../assets/images/discord.png";
import Twitter from "../../assets/images/twitter.png";

import "./SocialsSection.css";

function SocialsSection() {
  return (
    <div className="SocialsSection">
      <p className="SocialsSection_Rights">
        © 2021{" "}
        <a className="SocialsSection_LB" href="https://lostboy.la">
          lostboy.la
        </a>{" "}
        - All right reserved
      </p>
      <a
        className="SocialsSection_Contract"
        href="https://etherscan.io/address/0x43a16061986d2bcee1fc5e7ed132d5058eb25d68#code"
      >
        Verified Smart Contract
      </a>
    </div>
  );
}

export default SocialsSection;
