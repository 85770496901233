import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Contexts
import { Web3Context } from "../data/Web3Context/Web3Context";

// Pages
import HomePage from "../pages/HomePage/HomePage";

import "./App.css";

function App() {
  const { initializeWeb3 } = useContext(Web3Context);

  useEffect(() => {
    initializeWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <HomePage />
    </Router>
  );
}

export default App;
