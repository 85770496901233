import React, { Component } from "react";

import Header from "../../components/Header/Header";
import SocialsSection from "../../components/SocialsSection/SocialsSection";
import RedeemCard from "../../lib/RedeemCard/RedeemCard";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Header isHome />
        <RedeemCard />
        <SocialsSection />
      </div>
    );
  }
}

export default HomePage;
