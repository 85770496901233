import React, { useContext, useState, useRef, useEffect } from "react";
import NumericInput from "react-numeric-input";
import { SkyLightStateless } from "react-skylight";
import Loader from "react-loader-spinner";
import { Web3Context } from "../../data/Web3Context/Web3Context";

import Lostboy from "../../assets/images/lostboycard.png";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./RedeemCard.css";

function RedeemCard({ color, border }) {
  const { web3, account, connectMetamask, contract, lostgirlContract } = useContext(Web3Context);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintQuantity, setMintQuantity] = useState(1);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [canMint, setCanMint] = useState(true);
  const [boyBalance, setBoyBalance] = useState(0);
  const [collectedGirls, setCollectedGirls] = useState (0);
  const [merkleInfo, setMerkleInfo] = useState(null);
  const numericInput = useRef(null);

  useEffect(() => {
    if (!contract || !lostgirlContract) {
      return;
    }

    getEligibility ();
    getCollected();
    getBoyBalance ();

    const cI = setInterval (() => {
      getCollected();
    }, 5000);

    const cB = setInterval (() => {
      getBoyBalance ();
    }, 5000);
  
    return (() => {
      clearInterval (cI);
      clearInterval (cB);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract, lostgirlContract]);

  const getEligibility = async () => {
    const fetchRes = await fetch (`https://api.lostboy.io/merkle/${account}`);
    const json = await fetchRes.json();
    if (json.proof === "Not Found") {
      setMerkleInfo ({ maxCount: 0 });
    }
    else {
      setMerkleInfo (json);
    }
  };

  const getCollected = async () => { 
    const { claims } = lostgirlContract.methods;
    const collecteds = await claims (account).call();
    setCollectedGirls (collecteds);
  }

  const getBoyBalance = async () => {
    const { balanceOf } = contract.methods;
    const boys = await balanceOf (account).call();
    setBoyBalance (boys);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const mint = async () => {
    if (!canMint) {
      return;
    }

    setCanMint(false);

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    if (mintQuantity <= 0) {
      alert("Please input a valid number !");
      return;
    }

    const { claim } = lostgirlContract.methods;

    numericInput.current.refsInput.defaultValue = mintQuantity;

    claim(mintQuantity, merkleInfo.idx, merkleInfo.maxCount, merkleInfo.proof).estimateGas(
      {
        from: account,
      },
      async (err, gas) => {
        if (err) {
          if (err.message.indexOf("Claiming not") > -1) {
            alert("Claiming is not active yet !");
          }
          else if (err.message.indexOf("More than eligible") > -1) {
            alert("You are claiming more than your total eligible lostgirls.");
          }
          else if (err.message.indexOf("Invalid merkle proof") > -1) {
            alert("You are claiming from an un-eligible address, please check your network and address.");
          }
          else if (err.message.indexOf("Not enough lostboys") > -1) {
            alert("You do not have enough lostboys in your wallet, they should total the amount you are trying to collect/eligible for. What you have already collected also adds to that amount.");
          }
          else {
            alert("An unknown error has occured !");
          }
          setCanMint(true);
          return;
        }
        numericInput.current.refsInput.defaultValue = mintQuantity;
        claim(mintQuantity, merkleInfo.idx, merkleInfo.maxCount, merkleInfo.proof).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setCanMint(true);
            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          }
        );
      }
    );
  };


  return (
    <div>
      <SkyLightStateless
          isVisible={modalOpen}
          onCloseClicked={() => {
            setModalOpen(false);
          }}
        >
          <div className="Modal_Body">
            {!transactionConfirmed ? (
              <div className="Modal_Title">
                <Loader type="Audio" color="white" height={50} width={50} />
                <p className="Modal_Status">
                  Transaction Processing ... <br />
                  <span>
                    You can either wait or close this and check your wallet or
                    etherscan for confirmation
                  </span>
                </p>
              </div>
            ) : (
              <div className="Modal_Title">
                <p>Transaction Confirmed !</p>
              </div>
            )}

            <p>SUMMARY</p>
            <div className="separator"></div>
            <div className="Modal_Entries">
              <div className="Modal_Entry">
                <p>TRANSACTION INFO</p>
                <p>
                  <a href={`https://etherscan.io/tx/${transactionHash}`} target="_blank" rel="noopener">
                  {transactionHash}
                  </a>
                </p>
              </div>

              <div className="Modal_Entry">
                <p>FROM</p>
                <p>{account}</p>
              </div>
            </div>
            <div className="separator"></div>

            <div className="Modal_Actions">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Exit
              </button>
            </div>
            {transactionConfirmed && (
              <div className="Modal_Confirmation">
                Your transaction has now been confirmed ! Your NFT should now be
                minted , and visible on{" "}
                <a href="https://opensea.io/collection/lostgirl-nft">
                  Opensea.io{" "}
                </a>
                , please allow some time for it's metadata to show up.
              </div>
            )}
          </div>
        </SkyLightStateless>
      <div className="BoyPurchaseCard" style={{ borderColor: border }}>
        

        <img src={Lostboy} />

        <div className="BoyPurchaseCard_Sep"></div>
        <div>
        <p className="BoyPurchaseCard_ActionText">
             Collect your Lostgirl(s)
            </p>
        </div>

        {merkleInfo && (
          <NumericInput
            ref={numericInput}
            className="form-control"
            value={mintQuantity}
            onChange={setMintQuantity}
            min={1}
            max={merkleInfo.maxCount}
            step={1}
            precision={0}
            size={5}
            mobile
          />
        )}
        
        {account ? (
          merkleInfo && merkleInfo.maxCount > 0 ?
          <button
          onClick={() => {
            mint();
          }}
          className="BoyPurchaseCard_ActionButton"
        >
          {merkleInfo && merkleInfo.maxCount <= collectedGirls ? 
            "COLLECTED ALL ELIGIBLES" : "COLLECT LOSTGIRLS" 
          }
        </button> :  <button
          className="BoyPurchaseCard_ActionButton"
        >
          NOT ELIGIBLE
        </button>
        ) : (
          <button
            onClick={() => connectMetamask()}
            className="BoyPurchaseCard_ActionButton"
          >
            CONNECT WALLET
          </button>
        )}

        {account && (
          <>
          {merkleInfo &&
            merkleInfo.maxCount > 0 ?
            <p style={{ color: color, margin: "0", padding: "0" }}>
              Eligible For: <span><b>{merkleInfo.maxCount}</b> Girl(s)</span>
            </p>
            :
            <p style={{ color: color, margin: "0", padding: "0" }}>
              You are not eligible for Lostgirls.
            </p>
          }
          <p style={{ color: color, margin: "0", padding: "0" }}>
            Collected Already: <span><b>{collectedGirls}</b> Girls</span>
          </p>
          <p style={{ color: color, margin: "0", padding: "0" }}>
            Boys In Wallet: <span><b>{boyBalance}</b> Boy(s)</span>
          </p>
         </>
        )}

        {account && (
          <p className="BoyPurchaseCard_Address">{account}</p>
        )}

      </div>

    </div>
  );
}

export default RedeemCard;
