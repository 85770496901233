import React from "react";
import { Link } from "react-scroll";

import "./Header.css";

function Header({ isHome }) {

  return (
    <div className="Header_Container">
      <div className="Header">
        <div className="Header_Nav">
          <ul>
            <li>
              {isHome ? (
                <Link to="story" spy={true} smooth={true}>
                  COLLECT YOUR LOSTGIRL
                </Link>
              ) : (
                <a href="/">COLLECT YOUR LOSTGIRL</a>
              )}
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
}

export default Header;
